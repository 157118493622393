import React from 'react';
import { Pressable, View, Text } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';

class LineaCuota extends React.PureComponent {
  handleAdd = () => {
    this.props.setClic(Date.now());
    this.props.setProductoClic({ agregar: true, producto: this.props.item });
  };

  handleRemove = () => {
    this.props.setClic(Date.now());
    this.props.setProductoClic({ agregar: false, producto: this.props.item });
  };

  render() {
    const { item, AgregarDetalle } = this.props;
    const { Producto, Detalle, ListaProductosCombo, Cantidad } = item;
    const detalleTexto = Detalle ? ` - ${Detalle}` : '';
    const listaProductosComboTexto = ListaProductosCombo || '';
    const precioTotal = (Producto._PrecioActual * Cantidad).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return (
      <Pressable style={styles.container} onLongPress={() => AgregarDetalle(item)}>
        <View style={styles.row}>
          <View style={styles.counterContainer}>
            <Pressable style={styles.button} onPress={this.handleRemove}>
              <FontAwesome name="minus-square" size={30} color="red" />
            </Pressable>
            <Text style={styles.quantity}>{Cantidad}</Text>
            <Pressable style={styles.button} onPress={this.handleAdd}>
              <FontAwesome name="plus-square" size={30} color="#217346" />
            </Pressable>
          </View>
          <Text style={styles.productName}>
            {`${Producto.Nombre}${detalleTexto} ${listaProductosComboTexto}`}
          </Text>
          <Text style={styles.price}>
            {precioTotal}
          </Text>
        </View>
      </Pressable>
    );
  }
}

const styles = {
  container: {
    marginVertical: 2,
  },
  row: {
    flexDirection: "row",
    marginVertical: 2,
    alignItems: "center",
  },
  counterContainer: {
    flexDirection: "row",
    width: "25%",
    alignItems: "center",
  },
  button: {
    flex: 1,
    alignItems: "center",
  },
  quantity: {
    fontSize: 18,
    fontFamily: "FontLight",
    flex: 1,
    textAlign: "center",
  },
  productName: {
    fontFamily: "FontLight",
    flex: 1,
  },
  price: {
    fontFamily: "FontLight",
    width: "15%",
    textAlign: "right",
  },
};

export default LineaCuota;
