import { Dimensions, StyleSheet } from "react-native";
import { COLOR_EMPRESARIAL } from "./variables"
const { width,height } = Dimensions.get("window");

const Styles=StyleSheet.create({
    TextoEmpresaIn:
    {
      color:"#fff",
      fontFamily: "FontBold",
      fontSize: 15
    },
    TextoEmpresaOut:
    {
      color:COLOR_EMPRESARIAL,
      fontFamily: "FontBold",
      fontSize: 15
    },
    BotonEmpresaIn:
    {
      height:35,
      borderColor:COLOR_EMPRESARIAL,
      backgroundColor:COLOR_EMPRESARIAL,
      borderWidth:2,
      borderRadius:10,
      margin:10,
      flex:1,
      alignItems:"center",
      justifyContent:"center"
    },
    BotonEmpresaOut:
    {
      height:35,
      borderColor:COLOR_EMPRESARIAL,
      backgroundColor:"#fff",
      borderWidth:2,
      borderRadius:10,
      margin:10,
      flex:1,
      alignItems:"center",
      justifyContent:"center"
    },
    modalTitle: {
      alignItems: "flex-start",
      fontFamily: "FontBold",
      color: "#444",
      fontSize: 18,
    },
    flatList: {
      height: height / 2,
    },
    buttonTitle: {
      fontFamily: "FontBold",
      fontSize: 18,
    },
    button: {
      backgroundColor: COLOR_EMPRESARIAL,
      marginTop: 10,
    },
  
  
    container: {
      flex: 1,
      paddingVertical: 3,
      marginHorizontal: 5,
    },
    innerContainer: {
      alignContent: "center",
      justifyContent: "space-around",
    },
    row: {
      flexDirection: "row",
      backgroundColor: "#fff",
    },
    badgeContainer: {
      position: 'absolute',
      width: "100%",
      alignItems: "flex-end",
    },
    badge: {
      zIndex: 1000,
      marginRight: 10,
    },
    textContainer: {
      flex: 1,
      justifyContent: "space-between",
    },
    productName: {
      alignItems: "flex-start",
      fontFamily: "FontBold",
      color: "#444",
      fontSize: 14,
    },
    balanceContainer: {
      justifyContent: "space-between",
      marginEnd: 0,
      flexDirection: "row",
    },
    balanceText: {
      fontFamily: "FontMedium",
      flex: 1,
      fontSize: 14,
    },
    priceText: {
      fontFamily: "FontBold",
      flex: 1,
      textAlign: "right",
      color: "red",
      fontSize: 18,
    },
  })

  export {Styles}