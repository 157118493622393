import React,{useState,useEffect} from "react";
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  Dimensions,
  Alert
} from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { CambiarMesa, ListarMesas } from "../utils/consultasAPI/Mesas";

export default function CambiarMesas(props) {
  const { width } = Dimensions.get("window");
const [mostrarMensaje,setMostrarMensaje]=useState(false);
  const [mesas, setMesas]=useState([]);
  const [mesaDestino,setMesaDestino]=useState(null);
  const {mesaOrigen,setIsLoading,setisVisible_CambiarMesa,setRegreso}=props;
  console.log("PROPIEDADES",props);
  useEffect( ()=>{
    const loadData = async() => {
      setIsLoading(true);
    return ListarMesas().then((p)=>{
      setMesas(p);
      setIsLoading(false);
    }).catch(()=>{setIsLoading(false);})
    };
    loadData();
  },[]);

  function Cambiar_Mesa(mesaOrigen,mesaDestino)
  {
setMesaDestino(mesaDestino);
setMostrarMensaje(true);
  }


  return (
    <View style={{flex:1,color:"red"}}>
      <Text style={{textAlign:"center", fontFamily:"FontBold", fontSize:18, color:"#000",marginBottom:10}}>Seleccionar Mesa destino:</Text>
       <FlatList
          numColumns={5}
          style={{
            flex:1,
marginBottom:20,
            alignContent: "flex-start",
          }}
          showsVerticalScrollIndicator={false}
          data={mesas}
          renderItem={(mesa) => (
<Mesa Origen={mesaOrigen} item={mesa.item} width={width} setIsLoading={setIsLoading} setisVisible_CambiarMesa={setisVisible_CambiarMesa} Cambiar_Mesa={Cambiar_Mesa}/>
         )}
          keyExtractor={(item, index) => index.toString()}
        />
        <AwesomeAlert
          show={mostrarMensaje}
          showProgress={false}
          title="Alerta"
          message={mesaDestino&&"¿Esta seguro de cambiar todos los pedidos a la mesa "+mesaDestino.Grupo+" - "+mesaDestino.Nombre+"?"}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true} showCancelButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          cancelButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Si"
          cancelText="No"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setMostrarMensaje(false);
          }}
          onConfirmPressed={() => {
            setIsLoading(true);
            return  CambiarMesa(mesaOrigen,mesaDestino.Id).then((p)=>{
              setRegreso(Date.now);
              setIsLoading(false);
              setisVisible_CambiarMesa(false);
            }).catch((p)=>{console.log("Error",p)}).finally(()=>{setMostrarMensaje(false);
            });
          }}
        />
    </View>
  );
}

function Mesa (props){

  
    

    return <TouchableOpacity
        onPress={()=>{console.log("item del clic11",props.item); props.Cambiar_Mesa(props.Origen, props.item)}}
        style={{
          margin:2,
          justifyContent:"center",
          backgroundColor:props.item.Bloqueado?"#C8C8C8":props.item.Ocupado?"#ff6961":"#0ebb72",
          borderRadius: 10,
          width: props.width / 5-10,
          height: props.width / 5 - 20
        }}
      >
        <Text
          numberOfLines={2}
          style={{
            textAlign: "center",
            fontFamily:
              /*categoriaItem.item.Id === selectCategoria.IdCategoria
                ? "FontBold"
                :*/ "FontBold",
            color:
              /*  categoriaItem.item.Id === selectCategoria.IdCategoria
                ? "#fff"
                :*/ "#fff", //COLOR_EMPRESARIAL,
            fontSize: 13,
            marginHorizontal: 0,
            zIndex: 10,
          }}
        >
          {props.item.Nombre}
        </Text>
      </TouchableOpacity>
}