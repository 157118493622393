import React from "react";
import { StyleSheet, Dimensions } from "react-native";
import { Overlay } from "react-native-elements";

export default function ModalAbajo(props) {
  const { isVisible, setIsVisible, children } = props;
  const closeModal = () => setIsVisible(false);
  const { width, height } = Dimensions.get("window");

  return (
    <Overlay
      isVisible={isVisible}
      windowBackgroundColor="rgba(0,0,0,.5)"
      overlayBackgroundColor="transparent"
      overlayStyle={{
        height: height / 1.25,
        width: width,
        backgroundColor: "#fff",
        marginTop: height - height / 1.25,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      }}
      onBackdropPress={closeModal}
    >
      {children}
    </Overlay>
  );
}
