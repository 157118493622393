import { registerRootComponent } from 'expo';
import React from "react";
import Inicio from "./app/screens/Inicio";
import * as Font from "expo-font";
import {
  verificarToken,
} from "./app/utils/consultasAPI/InicioSesion";
import { ToastProvider } from 'react-native-toast-notifications';
import { SignalProvider } from './app/SingalContet';
import Loading from './app/Components/Loading';

export default class App extends React.Component {
  constructor() {
    super();
    this.state = {
      fontsLoaded: false,
      PrimeraVez: true,
      InicioSesion: false,
      Version: 0,
      Conectado: true
    };
  }

  async componentDidMount() {
    try {
      // Cargar fuentes y verificar el token de inicio de sesión en paralelo
      await Promise.all([
        Font.loadAsync({
          FontLight: require("./assets/fonts/OpenSans/OpenSans-Light.ttf"),
          FontLightItalic: require("./assets/fonts/OpenSans/OpenSans-LightItalic.ttf"),
          FontBold: require("./assets/fonts/OpenSans/OpenSans-Bold.ttf"),
          FontBoldItalic: require("./assets/fonts/OpenSans/OpenSans-BoldItalic.ttf"),
          FontMedium: require("./assets/fonts/OpenSans/OpenSans-Regular.ttf"),
          FontMediumItalic: require("./assets/fonts/OpenSans/OpenSans-Italic.ttf"),
          FontRegular: require("./assets/fonts/OpenSans/OpenSans-Regular.ttf"),
          FontBoton: require("./assets/fonts/MyriadPro/MyriadProBoldItalic.ttf"),
        }),
        verificarToken().then((pp) => {
          this.setState({
            InicioSesion: pp,
          });
        })
      ]);

      this.setState({ fontsLoaded: true });
    } catch (error) {
      console.error("Error loading fonts or verifying token:", error);
    }
  }

  render() {
    const { fontsLoaded } = this.state;

    // Mientras se cargan las fuentes, mostrar un componente de carga (puede ser un SplashScreen)
    if (!fontsLoaded) {
      return <Loading />; // O cualquier otro componente de carga
    }

    return (
      <ToastProvider placement="center" zIndex={9999}>
        <head>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"/>
</head>
        <SignalProvider>
          <Inicio
            PrimeraVez={this.state.PrimeraVez}
            InicioSesion={this.state.InicioSesion}
          />
        </SignalProvider>
      </ToastProvider>
    );
  }
}

