import React, { useEffect, useRef, useState } from 'react';
import { SafeAreaView, View, ScrollView, Text } from 'react-native';
import { Input, Button } from 'react-native-elements'; // Asegúrate de que estos componentes están disponibles
import { COLOR_EMPRESARIAL } from '../../utils/variables';

const styles = {
  container: {
    margin: 10,
    flex: 1,
  },
  title: {
    fontFamily: "FontBold",
    fontSize: 15,
  },
  inputStyle: {
    fontFamily: "FontRegular",
    marginTop: 10,
  },
  buttonStyle: {
    backgroundColor: COLOR_EMPRESARIAL,
  },
  titleButton: {
    height: 30,
    fontFamily: "FontBoton",
    fontSize: 20
  },
};

const InputWithFocus = React.forwardRef(({ value, onChange, placeholder }, ref) => (
  <Input
    ref={ref}
    inputStyle={styles.inputStyle}
    multiline
    maxLength={100}
    placeholder={placeholder}
    defaultValue={value}
    blurOnSubmit
    onChange={(e) => onChange(e.nativeEvent.text)}
    onSubmitEditing={(e) => onChange(e.nativeEvent.text)}
  />
));

export function ViewObservacion({ Seleccionado, setSeleccionado, setIsVisible }) {
  const ref = useRef();
  const [text, setText] = useState(Seleccionado.Observaciones);

  useEffect(() => {
    ref.current.focus();
  }, []);

  const handleContinue = () => {
    setSeleccionado({ ...Seleccionado, Observaciones: text });
    setIsVisible(false);
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={{ flex: 1 }}>
        <Text style={styles.title}>Ingrese una observación:</Text>
        <ScrollView>
          <InputWithFocus
            ref={ref}
            value={text}
            onChange={setText}
            placeholder="Observación..."
          />
        </ScrollView>
      </View>
      <Button
        buttonStyle={styles.buttonStyle}
        titleStyle={styles.titleButton}
        title="Continuar"
        onPress={handleContinue}
      />
    </SafeAreaView>
  );
}

export function ViewDetalle({ Seleccionado, setProductosPedido, productosPedido, setIsVisible }) {
  const ref = useRef();
  const [text, setText] = useState(Seleccionado.Detalle);

  useEffect(() => {
    ref.current.focus();
  }, []);

  const handleContinue = () => {
    const index = productosPedido.findIndex(p => p.Gui === Seleccionado.Gui);
    if (index !== -1) {
      productosPedido[index].Detalle = text;
      setProductosPedido([...productosPedido]); // Esto forzará una actualización del estado
    }
    setIsVisible(false);
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={{ flex: 1 }}>
        <Text style={styles.title}>
          {"Ingrese un detalle al producto\n-> "+ Seleccionado.Producto.Nombre}
        </Text>
        <ScrollView>
          <InputWithFocus
            ref={ref}
            value={text}
            onChange={setText}
            placeholder="Detalle..."
          />
        </ScrollView>
      </View>
      <Button
        buttonStyle={styles.buttonStyle}
        titleStyle={styles.titleButton}
        title="Continuar"
        onPress={handleContinue}
      />
    </SafeAreaView>
  );
}