import React from "react";
import { View, StyleSheet, Image } from "react-native";

export default function Logo_Cabecera() {
  return (
    <View style={Styles.LogoLogin}>
      <Image
      resizeMode="contain"
        style={Styles.imagen}
        source={require("../../assets/LudFactPrincipal.png")}
      />
    </View>
  );
}
const Styles = StyleSheet.create({
  LogoLogin: {
    //alignItems: "center",
    backgroundColor: "#fff", //Imagen Superior
    //textAlign: "center",
    padding: 0,
    margin: 0,
    alignItems: "center",
  },
  imagen: {
    width: "80%",
    overflow: "hidden",
    marginTop: 20,
    // maxWidth: 350,
    height: 150,
  },
});
