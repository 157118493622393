import { StyleSheet } from "react-native";

export default StyleSheet.create({

    BotonPrincipal:
    {
        backgroundColor:"#4CAF50", //Verde,
        marginVertical:5
        
    },
    BotonPrincipalBorde:
    {
        borderColor:"#4CAF50" //
    },
    BotonSecundario:{
backgroundColor:"#2196F3", //Azul
marginVertical:5
    },
    BotonSecundario2:{
backgroundColor:"#F44336", //Rojo
marginVertical:5
    },
    BotonSecundario3:
    {
backgroundColor:"#9E9E9E",//Gris
marginVertical:5
    },
    BotonSecundarioBorde:{

    },
    BotonSecundario2Borde:{

    },
    BotonSecundario3Borde:
    {

    }
});
