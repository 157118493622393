import React, { createContext, useEffect, useState } from 'react';
import { HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';

const SignalContext = createContext();

// Proveedor del contexto
export const SignalProvider = ({ children }) => {
  const [connection, setConnection] = useState(null);
  const [connectionState, setConnectionState] = useState(HubConnectionState.Disconnected);

  const startConnection = () => {

    console.log("este es connection",connection)
    if (connection && connection.state === HubConnectionState.Connected) {
      console.log('Ya hay una conexión activa.');
      return;
    }

    // Crear una nueva conexión de SignalR
    const newConnection = new HubConnectionBuilder()
      .withUrl('http://localhost:8069/Mesahub')  // Reemplaza con tu URL de SignalR
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();

    // Iniciar la conexión
    newConnection
      .start()
      .then(() => {
        console.log('Conexión a SignalR establecida');
        setConnection(newConnection);
        setConnectionState(HubConnectionState.Connected);
      })
      .catch((err) => {
        console.error('Error al conectar con SignalR: ', err);
        setConnectionState(HubConnectionState.Disconnected);
      });

    // Manejar eventos de reconexión
    newConnection.onreconnecting((error) => {
      console.log('Reconectando...', error);
      setConnectionState(HubConnectionState.Reconnecting);
    });

    newConnection.onreconnected(() => {
      console.log('Reconexión exitosa');
      setConnectionState(HubConnectionState.Connected);
    });

    newConnection.onclose(() => {
      console.log('Conexión cerrada');
      setConnectionState(HubConnectionState.Disconnected);
    });
  };

  const stopConnection = () => {
    if (connection && connection.state !== HubConnectionState.Disconnected) {
      connection.stop().then(() => {
        console.log('Conexión cerrada manualmente');
        setConnectionState(HubConnectionState.Disconnected);
      }).catch((err) => console.error('Error al cerrar la conexión: ', err));
    } else {
      console.log('No hay conexión activa para cerrar.');
    }
  };

  // Limpiar la conexión cuando el componente se desmonte
  useEffect(() => {
    return () => {
      if (connection) {
        connection.stop();
        setConnectionState(HubConnectionState.Disconnected);
      }
    };
  }, [connection]);

  return (
    <SignalContext.Provider value={{ connection, connectionState, startConnection, stopConnection }}>
      {children}
    </SignalContext.Provider>
  );
};

// Hook personalizado para usar el contexto de SignalR
export const useSignalR = () => {
  const context = React.useContext(SignalContext);
  if (!context) {
    throw new Error('useSignalR debe ser usado dentro de SignalProvider');
  }
  return context;
};