import {
  consultasAPI_Get, consultasAPI_GetToken, consultasAPI_Post, consultasAPI_PostToken
} from "./conexionAPI";


  export function ListarProductos(Nombre) {
   
        return consultasAPI_Get("api/Productos/Buscar?Valor="+Nombre).then((result) => {
            return result
          })
          .catch((error) => {
            throw "Error: " + error;
          });
    }
    export function ListarCategorias(idLocal) {
   
      return consultasAPI_PostToken("Producto/Categorias?idLocal="+idLocal).then((result) => {
        if (result.resultado)  
        return result.Datos;
      else
      return result.mensaje;
        })
        .catch((error) => {
          throw "Error: " + error;
        });
  }

    export function SaldoProduccion() {
   
      return consultasAPI_Get("Producto/SaldoProduccion").then((result) => {
        if (result.resultado)  
        return result.Datos;
      else
      return result.mensaje;
        })
        .catch((error) => {
          throw "Error: " + error;
        });
  }

  export function Lista(idLocal) {
   
    return consultasAPI_PostToken("Producto/Lista?idLocal="+idLocal).then((result) => {
      if (result.resultado)  
      return result.Datos;
    else
    return result.mensaje;
      })
      .catch((error) => {
        throw "Error: " + error;
      });
}
export function ListarProductosCombo(id) {
   
  return consultasAPI_GetToken("Producto/ListaCombo?id="+id).then((result) => {
    if (result.resultado)  
    return result.Datos;
  else
  return result.mensaje;
    })
    .catch((error) => {
      throw "Error: " + error;
    });
}

export function ListarProductosExtra(id) {
   
  return consultasAPI_GetToken("Producto/ListaExtras?id="+id).then((result) => {
    if (result.resultado)  
    return result.Datos;
  else
  return result.mensaje;
    })
    .catch((error) => {
      throw "Error: " + error;
    });
}

export function ListarProductosEstados(id) {
   
  return consultasAPI_GetToken("Producto/ListaEstados?id="+id).then((result) => {
    if (result.resultado)  
    return result.Datos;
  else
  return result.mensaje;
    })
    .catch((error) => {
      throw "Error: " + error;
    });
}
