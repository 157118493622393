import {
  conexionAPI,
  consultasAPI_Get,
  consultasAPI_sinToken2,
} from "./conexionAPI";
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Usuario } from "../variables";
import  jwtDecode  from "jwt-decode";

export async function   verificarToken() {
  const token = await AsyncStorage.getItem("Token");
  console.log(token);

  if (token === null || token === "" || token === "null") {
    AsyncStorage.removeItem("Token");
    return false;
  } else {
    if (jwtDecode(token).exp > Date.now() / 1000) return true;
    else {
      AsyncStorage.removeItem("Token");
      return false;
    }
  }
}
export async function Politicas() {
  return await consultasAPI_sinToken2("Seguridad/Politicas")
    .then((result) => {
      return result.Mensaje;
    })
    .catch((error) => {
      console.log("error al obtener Politicas", error);
      return "";
    });
}
export function CerrarSesion() {
  return AsyncStorage.removeItem("Token")
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
}

export async function DatosInicialesToken() {
  return await AsyncStorage.getItem("Token").then((token) => {
    let user = new Usuario();
    if (token === null || token === "" || token === "null") {
      return user;
    } else {
      user.Id = jwtDecode(token).ID;
      user.Nombres = jwtDecode(token).unique_name;
      console.log("user",user)
      return user;
    }
  });
}

export function RegistrarToken(token) {
  AsyncStorage.setItem("Token", token);
}

export async function IniciarSesion(FormData) {
  let tooken = await AsyncStorage.getItem("tokenMessaging");
  console.log("tokinciiiito", tooken);
  FormData = { ...FormData, ["token"]: tooken };
  console.log("FormData",FormData);
  return await conexionAPI("login/authenticate", JSON.stringify(FormData))
    .then((result) => {
      console.log("result en iniciarsesion", result);
      if (result === "") return "";
      else return true;
    })
    .catch((error) => {
      console.log( error);
      throw error;
    });
}

export async function EstadoAPP() {
  return await consultasAPI_Get("login/echoping")
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
}

export async function EchoUser() {
  return await consultasAPI_Get("login/echouser")
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
}
