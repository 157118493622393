  import React, { useEffect, useRef } from "react";
  import {
    View,
    StyleSheet,
    StatusBar,Text
  } from "react-native";
  import InicioSesion from "../Components/Cuenta/inicioSesion";
  import Logo_Cabecera from "../Components/Logo_Cabecera";
  import Toast from "react-native-easy-toast";
  import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
  import { useSignalR } from "../SingalContet";

  
  export default function VistaInicial(props) {
    const { desdeCuenta, handler } = props;
    const toastRef = useRef();
    const {  stopConnection } = useSignalR();
useEffect(()=>{stopConnection();},[])
    return (
      <View style={{flex:1,backgroundColor:"#fff"}}>
      <KeyboardAwareScrollView
        style={[desdeCuenta ? Styles.contenedor1 : Styles.contenedor]}
      >
        <View
          style={{
            top: 0,
            height: StatusBar.currentHeight+25,
            zIndex: 1001,
            backgroundColor: "#fff",
          }}
        />
        <View style={{flex:1}}>
          <Logo_Cabecera/>
          <Text  style={{ color:"#22223e", textAlign:"center", fontSize:25, fontFamily:"FontBold", marginTop:20}}>INICIO DE SESIÓN</Text>
          <InicioSesion
            toastRef={toastRef}
            desdeCuenta={desdeCuenta}
            handler={handler}
          />
        </View>
        <Toast ref={toastRef} position="center" Opacity={0.9} />
      </KeyboardAwareScrollView>
      </View>
    );
  }

  const Styles = StyleSheet.create({
    Opacity: {
      alignItems: "center",
      width: "95%",
    },
    contenedor: {
      flex: 1,
      //backgroundColor: "#fbfbfb",
      backgroundColor: "#fff",
      padding: 0,
      margin: 0,
    },
    contenedor1: {
      flex: 1,
      //backgroundColor: "#fbfbfb",
      backgroundColor: "#fff",
      padding: 0,
      margin: 0,
    },
    btnContainer: {
      width: "75%",
      alignSelf: "center",
      margin: 10,
    },

    ViewFooter: {
      flex: 1,
      flexDirection: "row",
      paddingTop: 10,

      justifyContent: "center",
    },
    ViewFooterText1: {
      color: "#626262",
      fontFamily: "FontRegular",
    },
    ViewFooterText2: {
      color: "#3b5999",
      fontFamily: "FontBold",
      //fontFamily: "FontBold",
    },
  });
