import {
   consultasAPI_GetToken, consultasAPI_PostToken
  } from "./conexionAPI";
  import {
    DatosInicialesToken,
  } from "./InicioSesion";
  export function VerMesa(id) {
  return consultasAPI_GetToken("Mesas/VerMesa1?id_mesa="+id).then((result) => {
    if (result.resultado)  
      return result.Datos;
    else
    throw result.mensaje
    })
    .catch((error) => {
      throw "Error: " + error;
    }); 
}

export function ListaProductos(id) {
   
  return consultasAPI_GetToken("Producto/ListaMesa?id="+id).then((result) => {
    if (result.resultado)  
      return result.Datos;
    else
    throw result.mensaje
    })
    .catch((error) => {
      throw "Error: " + error;
    });
}


export function CambiarMesa(origen, destino) {
   
  console.log("Mesas/Cambiar?origen="+origen+"&destino="+destino);
  return consultasAPI_GetToken("Mesas/Cambiar?origen="+origen+"&destino="+destino).then((result) => {
    if (result.resultado)  
      return result.Datos;
    else
    throw result.mensaje
    })
    .catch((error) => {
      throw "Error: " + error;
    });
}

export function CambiarNombre(origen, nombre) {
  return consultasAPI_GetToken("Mesas/CambiarNombreMesa?codigo="+origen+"&nombre="+nombre).then((result) => {
    if (result.resultado)  
      return result.Datos;
    else
    throw result.mensaje
    })
    .catch((error) => {
      throw "Error: " + error;
    });
}

export function AnularPedido(pedido) {
   
  console.log("Mesas/QuitarPedido?pedido="+JSON.stringify(pedido));
  return consultasAPI_PostToken("Mesas/QuitarPedido",JSON.stringify(pedido)).then((result) => {
    if (result.resultado)  
      return result.Datos;
    else
    throw result.mensaje
    })
    .catch((error) => {
      throw "Error: " + error;
    });
}

export function ListarMesas() {
    return consultasAPI_GetToken("Mesas/Ocupadas").then((result) => {
      if (result.resultado)  
        return result.Datos;
      else
      throw result.mensaje
      })
      .catch((error) => {
        throw "Error!!!: " + error;
      });
  }

  export function ListarAlmacenes() {
   
    return consultasAPI_PostToken("Producto/ListaAlmacenes").then((result) => {
      if (result.resultado)  
        return result.Datos;
      else
      throw result.mensaje
      })
      .catch((error) => {
        throw "Error!!!: " + error;
      });
  }


  export function RegistrarComanda(pedido,Mesa,Observaciones) {
   
    return DatosInicialesToken().then(p=>{
    
      let pedidon={Observaciones:Observaciones,ClienteSinRegistro:"MESA "+Mesa.Id, Personal:{IdPersonal:p.Id},Detalle:pedido,Mesa:{Id:Mesa.Id,Codigo:Mesa.Codigo,Nombre:Mesa.Nombre,Grupo:Mesa.Grupo}}
      return consultasAPI_PostToken("Mesas/RegistrarComanda",JSON.stringify(pedidon)).then((result) => {
      if (result.resultado)  
        return result.Datos;
      else
      throw result.mensaje
      })
      .catch((error) => {
        throw "Error: " + error;
      });
    })
  }

  export function ImprimirPreCuenta(pedido) {
    return consultasAPI_PostToken("Mesas/PreCuenta?_idmesa="+pedido,pedido).then((result) => {
      if (result.resultado)  
        return true;
      else
      throw result.mensaje
      })
      .catch((error) => {
        throw "Error: " + error;
      });
  }