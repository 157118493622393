import React, { useState, useEffect } from "react";
import { View, Text, FlatList, Pressable, Alert } from "react-native";
import { Button, Input } from "react-native-elements";
import { COLOR_EMPRESARIAL } from "../utils/variables";
import { ListaProductos, AnularPedido } from "../utils/consultasAPI/Mesas";
import AwesomeAlert from "react-native-awesome-alerts";

export default function QuitarPedido(props) {
  const [indexSelect, setIndexSelect] = useState(-1);
  const [mesas, setMesas] = useState([]);
  const { mesaOrigen, setIsLoading } = props;
  const [listaAnular, setListaAnular] = useState([]);
  const [text, setText] = useState("");
  const [ShowQuite,setShowQuite]=useState(false);
  const [ShowCorrecto,setShowCorrecto]=useState(false);
console.log("este es la mesa",mesas);
  useEffect(() => {
    const fetchListaProductos = async () => {
      setIsLoading(true);
      try {
        const productos = await ListaProductos(mesaOrigen.Codigo);
        setMesas(productos);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchListaProductos();
  }, []);

  const AgregarALista = (obj) => {
    setListaAnular([...listaAnular, obj]);
  };

  const QuitarDeLista = (obj) => {
    const NuevaLista = listaAnular.filter((lista) => lista.Id !== obj);
    setListaAnular(NuevaLista);
  };

function Quitar()
{
  console.log("entrando");
  
  if (text.trim() === "") {
   return <AwesomeAlert
          show={ShowQuite}
          showProgress={false}
          title="Alerta"
          message={"Ingrese el motivo de la anulación."}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Ok"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowQuite(false);
          }}
          onConfirmPressed={() => {
            setShowQuite(false);
          }}
        />
        return;
        }

  if (listaAnular.length > 0) {

    return <AwesomeAlert
          show={ShowQuite}
          showProgress={false}
          title="Alerta"
          message={`¿Está seguro de quitar producto(s) seleccionado(s) del pedido de la mesa ${mesaOrigen.Nombre} - ${mesaOrigen.Grupo}?`}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true} showCancelButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          cancelButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Si"
          cancelText="No"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowQuite(false);
          }}
          onConfirmPressed={() => {
              const Pedido = {
                Mesa: { Id: mesaOrigen.Id,Nombre:mesaOrigen.Nombre,Grupo:mesaOrigen.Grupo },
                Detalle: listaAnular,
                Observaciones: text,
                CodigoUnico:mesaOrigen.Codigo
              };
              setShowQuite(false);
              setIsLoading(true);
                AnularPedido(Pedido).then(p=>{
                  setIsLoading(false);
                  setShowCorrecto(true);
                }).catch((error)=> {
                setIsLoading(false);
                console.log("Error:", error);
              })
          }}
        />
  } else {
    return <AwesomeAlert
          show={ShowQuite}
          showProgress={false}
          title="Alerta"
          message={"Seleccione un producto para anular."}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Ok"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowQuite(false);
          }}
          onConfirmPressed={() => {
            setShowQuite(false);
          }}
        />
  }
}


  const RealizarQuitePedido = () => {
    if (text.trim() === "") {
      Alert.alert("Error", "Ingrese el motivo de la anulación.", [
        {
          text: "Ok",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel",
        },
      ]);
      return;
    }

    if (listaAnular.length > 0) {
      Alert.alert(
        "Alerta",
        `¿Está seguro de quitar producto(s) seleccionado(s) del pedido de la mesa ${mesaOrigen.Nombre} - ${mesaOrigen.Grupo}?`,
        [
          {
            text: "No",
            onPress: () => console.log("Cancel Pressed"),
            style: "cancel",
          },
          {
            text: "Si",
            onPress: async () => {
              const Pedido = {
                Mesa: { Id: mesaOrigen.Id,Nombre:mesaOrigen.Nombre,Grupo:mesaOrigen.Grupo },
                Detalle: listaAnular,
                Observaciones: "Anulacion",
              };
              setIsLoading(true);
              try {
                await AnularPedido(Pedido);
                setIsLoading(false);
                Alert.alert("Exito", "Pedido anulado correctamente!");
                props.setisVisible_QuitarPedido(false);
              } catch (error) {
                setIsLoading(false);
                console.log("Error:", error);
              }
            },
            style: "cancel",
          },
        ]
      );
    } else {
      Alert.alert("Error", "Seleccione un producto para anular.", [
        {
          text: "Ok",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel",
        },
      ]);
    }
  };

  return (
    <View style={{ flex: 1, color: "red" }}>
        <Text style={{ textAlign: "center", fontFamily: "FontBold", fontSize: 18, color: "#000", marginBottom: 10 }}>
          Seleccionar productos que desea anular:
        </Text>
        <FlatList
          showsVerticalScrollIndicator={true}
          data={mesas}
          style={{ flex:1, marginVertical: 5, borderColor: COLOR_EMPRESARIAL, padding: 10, borderWidth: 1, borderRadius: 5 }}
          renderItem={({ item, index }) => (
            <LineaCuota
              Lista={listaAnular}
              AgregarALista={AgregarALista}
              QuitarDeLista={QuitarDeLista}
              item={item}
              indexSelect={indexSelect}
              index={index}
              setIndexSelect={setIndexSelect}
            />
          )}
          keyExtractor={(item, index) => index.toString()}
        />
          <Input
            inputStyle={{
              fontFamily: "FontRegular",
              marginTop: 10,
            }}
            multiline={true}
            maxLength={100}
            placeholder="Motivo..."
            defaultValue={text}
            blurOnSubmit={true}
            onChange={(e) => setText(e.nativeEvent.text)}
            onSubmitEditing={(e) => setText(e.nativeEvent.text)}
          />
          <Button onPress={()=>setShowQuite(true)} titleStyle={{ fontFamily: "FontBold", fontSize: 18 }} title={"QUITAR PEDIDO"} buttonStyle={{ backgroundColor: COLOR_EMPRESARIAL }} />
          <Quitar/>
          <AwesomeAlert
          show={ShowCorrecto}
          showProgress={false}
          title="Alerta"
          message={"Eliminación correcta."}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Ok"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowCorrecto(false);
            props.setisVisible_QuitarPedido(false);
          }}
          onConfirmPressed={() => {
            setShowCorrecto(false);
            props.setisVisible_QuitarPedido(false);
          }}
        />
    </View>
  );
}

function ExisteLista(lista, obj) {
  return lista.some((item) => item.Id === obj);
}

function LineaCuota({ Lista, AgregarALista, QuitarDeLista, item }) {
  const backgroundColor = ExisteLista(Lista, item.Id) ? "red" : "#fff";
  const fontFamily = ExisteLista(Lista, item.Id) ? "FontBold" : "FontLight";

  return (
    <Pressable style={{ marginVertical: 2 }} onPress={() => (ExisteLista(Lista, item.Id) ? QuitarDeLista(item.Id) : AgregarALista(item))}>
      <View style={{ flexDirection: "row" }}>
        <Text style={{ backgroundColor, fontFamily, flex: 1 }}>{item.ProductoDetalleTotal}</Text>
      </View>
    </Pressable>
  );
}